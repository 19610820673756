<template>
  <div>
    <div ref="beforeTable" class="pa-4 d-flex align-center">
      <h2>
        Empresas
      </h2>
      <v-text-field
        dense filled
        class="flex-grow-1 mx-4"
        hide-details
        v-model="searchQuery"
        placeholder="Pesquisar por nome"
        prepend-inner-icon="mdi-magnify"
        clearable
      />
      <v-btn height="40px" color="primary" @click="$refs.viewCompany.open()">
        Novo Cliente
      </v-btn>
    </div>
    <v-divider />
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="companies"
      fixed-header
      :height="tableHeight"
      hide-default-footer
      disable-pagination
      sort-by="name"
      @click:row="$refs.viewCompany.open($event)"
      no-results-text="Nenhum cliente encontrado"
      no-data-text="Nenhum cliente encontrado"
      loading-text="Carregando... Por favor, aguarde"
    >
      <template #no-data>
        <div v-if="tryAgain" class="py-5 d-flex flex-column align-center black--text">
          Erro ao carregar clientes
          <br/>
          <v-btn small depressed color="error" class="mt-4" @click="tryAgain = false">
            tentar novamente
          </v-btn>
        </div>
        <template v-else>
          Nenhum cliente encontrado
        </template>
      </template>

      <template #[`item.document`]="{ item }">
        {{ item.cnpj || item.cpf }}
      </template>

      <template #[`body.append`]>
        <tr v-if="!fullyLoaded && !tryAgain && started">
          <td :colspan="headers.length">
            <scroll-loader
              :loader-method="loadCompanies"
              :loader-disable="fullyLoaded"
            >
              <v-progress-circular
                indeterminate
                :size="20"
                :width="2"
              />
            </scroll-loader>
          </td>
        </tr>
      </template>

    </v-data-table>

    <v-dialog v-model="askCreateUser" max-width="600px">
      <v-card>
        <v-toolbar flat dark color="primary" max-height="35px" />
        <v-card-title class="px-4 py-3">
          Deseja criar um usuário para o novo cliente?
        </v-card-title>
        <v-card-actions class="pb-4">
          <v-spacer />
          <v-btn text @click="askCreateUser = false">
            Mais tarde
          </v-btn>
          <v-btn
            class="ml-2"
            color="primary"
            :loading="loading"
            @click="
              askCreateUser = false;
              $refs.createUser.openWithCompany(companyToNewUser);
            "
          >
            Criar usuário
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ViewEditAndCreateCompany
      ref="viewCompany"
      @updated="
        resetLoader();
        $emit('companiesUpdated');
      "
      @created="
        askCreateUser = true;
        companyToNewUser = $event;
        $emit('companiesUpdated');
      "
    />
    <ViewEditCreateUserDialog ref="createUser" />
  </div>
</template>

<script>
import ViewEditAndCreateCompany from "@/components/ViewEditAndCreateCompany.vue";
import ViewEditCreateUserDialog from "@/components/ViewEditCreateUserDialog.vue";

export default {
  name: "Companies",
  components: {
    ViewEditAndCreateCompany,
    ViewEditCreateUserDialog,
  },

  props: ["contentHeight"],

  data: () => ({
    started: false,
    fullyLoaded: false,
    limit: 30,
    changeTimeout: undefined,

    loading: false,
    askCreateUser: false,
    companies: [],
    companyToNewUser: "",
    tryAgain: false,
    searchQuery: "",
    headers: [
      { text: "Nome", value: "name" },
      { text: "Documento", value: "document" },
      { text: "", value: "actions", sortable: false, align: "end" },
    ],
    spaceBeforeTable: 72,
  }),

  computed: {
    tableHeight() {
      return (this.contentHeight - 1 - this.spaceBeforeTable) + "px";
    },
  },

  methods: {
    resetLoader(){
      this.started = true;
      if (this.changeTimeout != undefined){
        clearTimeout(this.changeTimeout);
      }
      this.changeTimeout = setTimeout(() => {
        if (this.loading){
          this.resetLoader();
        } else {
          this.fullyLoaded = false;
          this.$set(this, 'companies', []);
        }
      }, 300);
    },
    reload(){
      if (this.started){
        this.resetLoader();
      } else {
        this.started = true;
      }
    },
    loadCompanies() {
      this.tryAgain = false;
      this.loading = true;
      this.$axios
        .get(`/companies/admin`, {
          params: {
            search_query: this.searchQuery,
            limit: this.limit,
            offset: this.companies.length
          }
        })
        .then((response) => {
          this.companies = [
            ...this.companies,
            ...response.data
          ];
          if (response.data.length < this.limit){
            this.fullyLoaded = true;
          }
        })
        .catch((error) => {
          this.tryAgain = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  watch: {
    searchQuery(){ this.resetLoader(); },
  },

  mounted() {
    this.$nextTick(() => {
      this.spaceBeforeTable = this.$refs.beforeTable.clientHeight;
    });
  },
};
</script>

<style>
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: inset 0px 0px 1px 0px !important;
}
</style>
